import React from 'react'
import {motion} from 'framer-motion'

export default function PageTitle({children}) {

  const titleVariants = {
    pre: {
      x:"0px",
      "--underline-width": "0px"
    },
    vis: {
      x:"0px",
      "--underline-width": "200px",
      transition: {
        type: "tween",
        duration: 0.5,
        ease: "easeInOut"
      }
    },
    post: {
      "--underline-width": "0px",
      transition: {
        duration: 0.5
      }
    }
  }

  return (
    <motion.h1 variants={titleVariants}>{children}</motion.h1>
  )
}
