// extracted by mini-css-extract-plugin
export const homeHolder = "PageStyles-module--homeHolder--3To93";
export const homeIntroText = "PageStyles-module--homeIntroText--3Tied";
export const homeTitle = "PageStyles-module--homeTitle--17Rnx";
export const aboutHolder = "PageStyles-module--aboutHolder--2GEcP";
export const aboutLHS = "PageStyles-module--aboutLHS--27lNy";
export const aboutRHS = "PageStyles-module--aboutRHS--tS9lc";
export const lyricsHolder = "PageStyles-module--lyricsHolder--27b6L";
export const lyricsLHS = "PageStyles-module--lyricsLHS--amNky";
export const lyricsRHS = "PageStyles-module--lyricsRHS--4W1Vg";
export const linksHolder = "PageStyles-module--linksHolder--1uZvr";
export const musicNav = "PageStyles-module--musicNav--1gHRq";
export const releasesHolder = "PageStyles-module--releasesHolder--3mcWA";
export const releaseHolder = "PageStyles-module--releaseHolder--3uJNb";
export const sightAndSoundPageHolder = "PageStyles-module--sightAndSoundPageHolder--1jJ6o";
export const sasHolder = "PageStyles-module--sasHolder--1n26-";
export const contactHolder = "PageStyles-module--contactHolder--3G7d1";
export const mailingListHolder = "PageStyles-module--mailingListHolder--17uU7";