import { graphql } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import React, {useState} from 'react'
import PageTitle from '../components/PageTitle'
import Seo from '../components/seo'
import {musicHolder, releaseHolder, musicNav, releasesHolder} from '../styles/PageStyles.module.scss'
import {AnimatePresence, motion} from 'framer-motion'


const Release = ({release, image})=> {
  const releaseVariants = {
    loading: {
      x: "-2rem",
      opacity:0
    },
    visible: {
      x: "0rem",
      opacity:1,
      transition:{
        duration: 0.5,
        type:'tween',
        ease:"easeInOut"
      }
    },
    unloading: {
      x: "2rem",
      opacity:0,
      transition:{
        duration: 0.5,
        type:'tween',
        ease:"easeInOut"
      }
    }
  }

  const img = getImage(image)
  return (
    <motion.li className={releaseHolder} variants={releaseVariants}>
      <GatsbyImage image={img} alt={release.title} />
      <div className="titleAndDate">
        <h3>{release.title}</h3>
        <p>{release.date}</p>
      </div>
      <ul className="links">
        {
          release.links.map(link=> (
          <li key={link.url}><a href={link.url} target="_blank" rel="noreferrer" >{link.text}</a></li>
          ))
        }
      </ul>
    </motion.li>
  )
}




export default function MusicPage({data}) {

  const switcherVariants = {
    selected: {
      "--underline-width": "100%"
    },
    unselected: {
      "--underline-width": "0%"
    }
  }

  const releasesVariants = {
    loading: {
      opacity:1
    },
    visible: {
      opacity:1,
      transition:{
        duration: 0.5,
        type:'tween',
        ease:"easeInOut",
        staggerChildren: 0.2,
        staggerDirection: 1
      }
    },
    unloading: {
      opacity:0,
      transition:{
        duration: 0.5,
        type:'tween',
        ease:"easeInOut",
        staggerChildren: 0.2,
        staggerDirection: 1
      }
    }
  }

  const [show, setShow] = useState('singles')

  const allMusic = data.allDataJson.nodes[0].music
  const singles = allMusic.filter(release => release.type === "single")
  const albums = allMusic.filter(release => release.type === "album")

  const allImages = data.allFile.nodes

  return (
    <article className={musicHolder}>
      <Seo title="Music" />
      <PageTitle>Music</PageTitle>
      <section>
        <h5>
          <p>Dot Allison is proud to announce her forthcoming album “Heart Shaped Scars” will be released on <a href="https://sarecordings.com/format/724541-heart-shaped-scars" target="_blank" rel="noreferrer" >SA Recordings</a> on 30th July.</p>
          <p>The album is available to preorder through <a href="https://sarecordings.com/format/724541-heart-shaped-scars" target="_blank" rel="noreferrer" >SA Recordings</a> or <a href="https://dotallison.bandcamp.com/album/heart-shaped-scars" target="_blank" rel="noreferrer" >Bandcamp</a>. Listen to the singles through <a href="https://open.spotify.com/artist/69IZoi4l9e7KcUpbgP9K7e?si=58ZbCiNXSfS87lDrD-GdkA" target="_blank" rel="noreferrer" >Spotify</a> and <a href="https://music.apple.com/us/artist/dot-allison/460529" target="_blank" rel="noreferrer" >Apple Music</a>.</p>
        </h5>
      </section>
      <section>
        <ul className={musicNav}>
          <li><motion.button onClick={()=>setShow('singles')} variants={switcherVariants} whileHover="selected" animate={ show === 'singles' ? "selected" : "unselected" }>Singles</motion.button></li>
          <li><motion.button onClick={()=>setShow('albums')} variants={switcherVariants} whileHover="selected" animate={ show === 'albums' ? "selected" : "unselected" } >Albums</motion.button></li>
        </ul>

        <div className={releasesHolder}>
          <AnimatePresence>
          {
            show === 'singles' &&
            ( singles?.length>0
              ?
              <motion.ul key="singles" id="singles" className="releases" variants={releasesVariants} initial="loading" exit="unloading" animate="visible">
              {
                singles.map(single => {
                  const image = allImages.find(img => img.name === single.image )

                  return <Release release={single} image={image} key={single.title} />
                })
              }
              </motion.ul>
              :
              <p>No singles found</p>
            )
          }
          {
            show === 'albums' &&
            ( albums?.length>0 
              ?
              <motion.ul key="albums" id="albums" className="releases" variants={releasesVariants} initial="loading" exit="unloading" animate="visible">
              {
                albums.map(album => {

                  const image = allImages.find(img => img.name === album.image )

                  return <Release key={album.title} release={album} image={image} />
                })
              }
              </motion.ul>
              :
              <p>No albums found</p>
            )
          }
          </AnimatePresence>
        </div>
      </section> 

    </article>
  )
}

export const query = graphql`
query MusicQuery {
  allDataJson {
    nodes {
      music {
        title
        date
        image
        type
        links {
          text
          url
        }
      }
    }
  }
  allFile(filter: {relativeDirectory: {eq: "music"}}) {
    nodes {
      relativeDirectory
      name
      childImageSharp {
        gatsbyImageData(
          width: 500
          height:500
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
  }
}
`